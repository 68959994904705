import React, { useState } from "react";

/*
 * Displays the metadata of the selected chunks and the hovered block
 */
let arr = [];

for (let i = 0; i <= 74; i++) {
    arr.push(Math.random() * (35 - 14) + 14);
}

export default function SummaryPanel(props) {
    const [view, setView] = useState("GR");

    const _renderStats = (stats) => {
        return Object.keys(stats).map((key) => (
            <tr key={key}>
                <td>
                    <b>{key}</b>
                </td>
                <td>{stats[key]}</td>
            </tr>
        ));
    };

    const _renderHoverInfo = () => {
        const { hoveredBlock } = props;
        if (!hoveredBlock) {
            return null;
        }
        const { blockId, blockData, position, biome, block, GR, DEN, NEUT } =
            hoveredBlock;

        return _renderStats({
            Depth: `${6500 - position[1] * 100} feet`,
            Coords: `X:${position[0]} Y:${position[1]} Z:${position[2]}`,
            GR: `${GR}`,
            NEUT: `${NEUT}`,
            DEN: `${DEN}`,
        });
    };

    return (
        <div className="absolute right-[10px] top-[10px]">
            <div className=" w-[280px] h-[108px] top-2 right-4 bg-[#181818] rounded-lg border-[#252525] border">
                <h1 className="text-white m-3 font-bold text-lg">Views</h1>
                <div className="px-3 relative">
                    <select
                        id="location"
                        name="location"
                        className="mt-1 block w-full rounded-md bg-[#252525] border-gray-300 py-2 pl-3 pr-10 text-white text-base focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                        defaultValue="GR"
                        onChange={(e) => {
                            props._viewChangeHandler(e.target.value);
                        }}
                    >
                        <option>GR</option>
                        <option>NEUT</option>
                        <option>DEN</option>
                    </select>
                </div>
            </div>
            <table className="mt-3 text-white">
                <tbody>{_renderHoverInfo()}</tbody>
            </table>
        </div>
    );
}
