import React, { useRef, useState, useCallback, useEffect } from "react";
import Map, { Source, Layer, Popup } from "react-map-gl";
import DeckGL from "@deck.gl/react";
import { LineLayer } from "@deck.gl/layers";
import "mapbox-gl/dist/mapbox-gl.css";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import "@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css";
import MapboxDraw from "@mapbox/mapbox-gl-draw";

export default function MiniMap(props) {
    const [showPopup, setShowPopup] = useState(false);
    const [popupStats, setPopupStats] = useState({});

    const mapRef = useRef();
    let draw = new MapboxDraw({
        defaultMode: "simple_select",
        displayControlsDefault: false,
    });
    let geojson;
    if (props.selected.type == "rectangle") {
        geojson = {
            type: "FeatureCollection",
            features: [
                {
                    type: "Feature",
                    properties: {},
                    geometry: {
                        type: "Polygon",
                        coordinates: [
                            [
                                [
                                    props.selected.lower_left_lat_lon[1],
                                    props.selected.lower_left_lat_lon[0],
                                ],
                                [
                                    props.selected.upper_right_lat_lon[1],
                                    props.selected.lower_left_lat_lon[0],
                                ],
                                [
                                    props.selected.upper_right_lat_lon[1],
                                    props.selected.upper_right_lat_lon[0],
                                ],
                                [
                                    props.selected.lower_left_lat_lon[1],
                                    props.selected.upper_right_lat_lon[0],
                                ],
                            ],
                        ],
                    },
                },
            ],
        };
    }

    const layerStyle = {
        id: "bbox",
        type: "fill",
        paint: {
            "fill-color": "#FFFFFF",
            "fill-opacity": 0.5,
        },
    };

    const onMapLoad = useCallback(() => {
        mapRef.current.addControl(draw, "top-left");

        mapRef.current.on("click", (e) => {
            const features = mapRef.current.queryRenderedFeatures(e.point);

            for (let i = 0; i < features.length; i++) {
                let feature = features[i];
                if (feature.layer.id == "well-locations") {
                    const dict = {
                        type: "well",
                        api: feature.properties.API,
                        longitude: feature.properties.SurfaceLongitudeWGS84,
                        latitude: feature.properties.SurfaceLatitudeWGS84,
                    };
                    props._onSelect(dict);
                    break;
                } else {
                    props._onSelect({
                        type: "latlng",
                        latitude: e.lngLat.lat,
                        longitude: e.lngLat.lng,
                    });
                }
            }
        });

        let start;

        mapRef.current.on("mousedown", (e) => {
            if (!(e.originalEvent.shiftKey && e.originalEvent.button === 0))
                return;

            start = e.lngLat;
            mapRef.current.on("mouseup", mouseUp);
        });

        const mouseUp = (e) => {
            finish([start, e.lngLat]);
        };

        const finish = (bbox) => {
            mapRef.current.off("mouseup", mouseUp);
            const lng1 = bbox[0].lng;
            const lat1 = bbox[0].lat;
            const lng2 = bbox[1].lng;
            const lat2 = bbox[1].lat;

            const lowerLeft = {
                lower_left_lat_lon: [
                    Math.min(lat1, lat2),
                    Math.min(lng1, lng2),
                ],
            };
            const upperRight = {
                upper_right_lat_lon: [
                    Math.max(lat1, lat2),
                    Math.max(lng1, lng2),
                ],
            };

            const bboxCoords = { ...lowerLeft, ...upperRight };

            props._onSelect({
                type: "rectangle",
                ...bboxCoords,
            });
            return;
        };

        mapRef.current.on("mousemove", (e) => {
            const features = mapRef.current.queryRenderedFeatures(e.point, {
                layers: ["well-locations"],
            });
            mapRef.current.getCanvas().style.cursor = features.length
                ? "pointer"
                : "crosshair";

            if (features.length == 0) {
                setShowPopup(false);
                return;
            } else {
                setPopupStats({ ...e.lngLat, api: features[0].properties.API });
                setShowPopup(true);
            }
        });
    }, []);

    return (
        <div className="absolute bottom-0 right-0">
            {props.selected.type == "well" && (
                <h1 className="text-white">
                    {props.selected.api
                        ? "Selected Well: " + props.selected.api
                        : "No well selected"}
                </h1>
            )}
            {props.selected.type == "latlng" && (
                <>
                    <h1 className="text-white">
                        {"Latitude: " + props.selected.latitude}
                    </h1>

                    <h1 className="text-white">
                        {"Longitude: " + props.selected.longitude}
                    </h1>
                </>
            )}
            {props.selected.type == "rectangle" && (
                <>
                    <h1 className="text-white">
                        {"Upper Right Coords: " +
                            props.selected.upper_right_lat_lon[0].toFixed(4) +
                            ", " +
                            props.selected.upper_right_lat_lon[1].toFixed(4)}
                    </h1>

                    <h1 className="text-white">
                        {"Lower Left Coords: " +
                            props.selected.lower_left_lat_lon[0].toFixed(4) +
                            ", " +
                            props.selected.lower_left_lat_lon[1].toFixed(4)}
                    </h1>
                </>
            )}
            <Map
                ref={mapRef}
                onLoad={onMapLoad}
                cursor={"crosshair"}
                doubleClickZoom={false}
                dragRotate={false}
                style={{ width: 600, height: 600 }}
                maxPitch={0}
                boxZoom={false}
                mapStyle="mapbox://styles/acretech/cl9yazwsw002315qfhly4yq8i"
                mapboxAccessToken="pk.eyJ1IjoiYWNyZXRlY2giLCJhIjoiY2p3YjA2dDEyMDA2bDRhbXNramg3dGR3dSJ9.Dk7kFUINfShSh2u8gYoOFA"
            >
                {showPopup && (
                    <Popup
                        longitude={popupStats.lng}
                        latitude={popupStats.lat}
                        closeButton={false}
                        closeOnClick={false}
                    >
                        {"Well API: " + popupStats.api}
                    </Popup>
                )}
                {props.selected.type == "rectangle" && (
                    <>
                        <Source id="my-data" type="geojson" data={geojson}>
                            <Layer {...layerStyle} />
                        </Source>
                    </>
                )}
            </Map>
        </div>
    );
}
