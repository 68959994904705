import React, { Component, useCallback, useEffect, useState } from "react";
import { render } from "react-dom";
import DeckGL, {
    LightingEffect,
    AmbientLight,
    DirectionalLight,
    OrbitView,
} from "deck.gl";
import MinecraftLayer from "./minecraft-layer";
import MiniMap from "./components/map";
import SummaryPanel from "./components/summary-panel";
import About from "./components/about";
const localData = require("./results.json");
import "./app.css";

import {
    readChunks,
    REGION_FILE_PATTERN,
    getBlockTemperature,
    getBlockHumidity,
    isBlockOpaque,
} from "./utils/mca-parser";

const sampleFile = "r.5.13.mca";

const INITIAL_VIEW_STATE = {
    target: [0, 0, 0],
    zoom: 0,
    orbitAxis: "Y",
    rotationX: 30,
    rotationOrbit: 30,
    minZoom: -3,
    maxZoom: 10,
};

/*
let data = [];

let minX = 2560;
let maxX = 2688;
//let maxX = 2575;
let minZ = 6656;
let maxZ = 6784;
//let maxZ = 6671;

for (let k = 0; k <= 100; k++) {
    let blockData = Math.round(Math.random() * 15);
    for (let i = minX; i <= maxX; i++) {
        for (let j = minZ; j <= maxZ; j++) {
            data.push({
                biome: { name: "Forest", temp: 0.7, humidity: 0.8 },
                block: { name: "Stone", opaque: true },
                blockId: 159,
                blockData: blockData,
                index: 0,
                lighting: 0,
                position: [i, k + 1, j],
            });
        }
    }
}*/

let minX = 2560;
let minZ = 6656;

let gradient = [
    {
        range: 112,
        blockID: 35,
        blockData: 0,
    },
    {
        range: 107,
        blockID: 35,
        blockData: 1,
    },
    {
        range: 102,
        blockID: 35,
        blockData: 2,
    },
    {
        range: 96,
        blockID: 35,
        blockData: 3,
    },
    {
        range: 91,
        blockID: 35,
        blockData: 4,
    },
    {
        range: 86,
        blockID: 35,
        blockData: 5,
    },
    {
        range: 80,
        blockID: 35,
        blockData: 6,
    },
    {
        range: 75,
        blockID: 35,
        blockData: 7,
    },
    {
        range: 70,
        blockID: 35,
        blockData: 10,
    },
    {
        range: 64,
        blockID: 35,
        blockData: 11,
    },
    {
        range: 59,
        blockID: 35,
        blockData: 12,
    },
    {
        range: 54,
        blockID: 35,
        blockData: 13,
    },
    {
        range: 49,
        blockID: 35,
        blockData: 14,
    },
    {
        range: 43,
        blockID: 35,
        blockData: 15,
    },
    {
        range: 38,
        blockID: 159,
        blockData: 1,
    },
    {
        range: 33,
        blockID: 159,
        blockData: 2,
    },
    {
        range: 27,
        blockID: 159,
        blockData: 3,
    },
    {
        range: 22,
        blockID: 159,
        blockData: 4,
    },
    {
        range: -1000000000,
        blockID: 159,
        blockData: 5,
    },
];

const LIGHTING_EFFECT = new LightingEffect({
    ambient: new AmbientLight({
        color: [255, 255, 255],
        intensity: 2.5,
    }),
});
let formattedData = [];
export default function App() {
    const [state, setState] = useState({
        viewState: INITIAL_VIEW_STATE,
        sliceY: 1,
        selection: {
            chunks: [],
            data: null,
        },
        hoveredBlock: null,
    });
    const [selected, setSelected] = useState({ api: "" });

    const [data, setData] = useState([]);

    useEffect(() => {
        console.log(selected);
        if (selected.type == "well") {
            console.log(selected);
            wellQuery(selected);
        } else if (selected.type == "latlng") {
            latLngQuery({
                latitude: selected.latitude,
                longitude: selected.longitude,
            });
        } else if (selected.type == "rectangle") {
            rectangleQuery({
                lower_left_lat_lon: selected.lower_left_lat_lon,
                upper_right_lat_lon: selected.upper_right_lat_lon,
            });
        }
    }, [selected]);

    const rectangleQuery = useCallback((selected) => {
        console.log(selected);
        const auth = btoa("frontend" + ":" + "frontend123");
        fetch(
            "https://urchin-app-gwy4a.ondigitalocean.app/get_3dsubsurface_given_rectangle",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Basic ${auth}`,
                },
                body: JSON.stringify(selected),
            }
        )
            .then((response) => response.json())
            .then((response) => _readChunks(response.chunks));
    });

    const latLngQuery = useCallback((selected) => {
        console.log(selected);
        const auth = btoa("frontend" + ":" + "frontend123");
        fetch(
            "https://urchin-app-gwy4a.ondigitalocean.app/get_3dsubsurface_given_latitude_and_longitude",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Basic ${auth}`,
                },
                body: JSON.stringify(selected),
            }
        )
            .then((response) => response.json())
            .then((response) => _readChunks(response.chunks));
    });

    const wellQuery = useCallback((well) => {
        const auth = btoa("frontend" + ":" + "frontend123");
        fetch(
            "https://urchin-app-gwy4a.ondigitalocean.app/get_3dsubsurface_given_well",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Basic ${auth}`,
                },
                body: JSON.stringify(well),
            }
        )
            .then((response) => response.json())
            .then((response) => _readChunks(response.chunks));
    });

    const query = useCallback((query) => {
        const auth = btoa("frontend" + ":" + "frontend123");
        fetch(
            "https://urchin-app-gwy4a.ondigitalocean.app/get_3dsubsurface_given_latitude_and_longitude",
            {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: `Basic ${auth}`,
                },
                body: JSON.stringify(query),
            }
        )
            .then((response) => response.json())
            .then((data) => _readChunks(data.chunks));
    }, []);

    const _readChunks = (chunks) => {
        console.log(chunks);
        //chunks = localData;
        console.log(chunks);
        formattedData = [];
        for (let i = 0; i < chunks.length; i++) {
            let curData = chunks[i];
            let gr = curData[3];
            //let gr = curData.GR;
            let blockID;
            let blockData;

            for (let j = 0; j < gradient.length; j++) {
                let curBucket = gradient[j];

                if (gr >= curBucket.range) {
                    blockID = curBucket.blockID;
                    blockData = curBucket.blockData;
                    break;
                }
            }

            formattedData.push({
                biome: { name: "Forest", temp: 0.7, humidity: 0.8 },
                block: { name: "Stone", opaque: true },
                blockId: blockID,
                blockData: blockData,
                index: 0,
                lighting: 0,
                //position: [curData.X, curData.Y, curData.Z],
                //GR: curData.GR,
                //NEUT: curData.NEUT,
                //DEN: curData.DEN,
                position: [curData[0], curData[1], curData[2]],
                GR: curData[3],
                NEUT: curData[4],
                DEN: curData[5],
            });
        }
        setData(formattedData);
        console.log(formattedData);

        let selection = readChunks(chunks);
        selection = {
            blockCount: 15856,
            bounds: {
                maxX: 32,
                maxY: 49,
                maxZ: 32,
                minX: 0,
                minY: 0,
                minZ: 0,
            },
            chunks: [0, 0],
            data: chunks,
        };

        const { bounds } = selection;

        const scale =
            Math.min(window.innerWidth, window.innerHeight) /
            Math.max(
                bounds.maxX - bounds.minX,
                bounds.maxY - bounds.minY,
                bounds.maxZ - bounds.minZ
            );

        const viewState = {
            ...INITIAL_VIEW_STATE,
            target: [
                (bounds.minX + bounds.maxX) / 2,
                (bounds.minY + bounds.maxY) / 2,
                (bounds.minZ + bounds.maxZ) / 2,
            ],
            zoom: 3.5,
        };

        setState({ ...state, selection, viewState });
    };

    const _onSliceY = (e) => {
        setState({ ...state, sliceY: e.target.value });
    };

    const _onHoverBlock = ({ object }) => {
        setState({ ...state, hoveredBlock: object });
    };

    const _onViewStateChange = ({ viewState }) => {
        setState({ ...state, viewState: viewState });
    };

    const _onWebGLInitialized = (gl) => {
        gl.enable(gl.DEPTH_TEST);
        gl.depthFunc(gl.LEQUAL);
        gl.enable(gl.CULL_FACE);
    };

    const _viewChangeHandler = (view) => {
        let newFormattedData = [];
        for (let i = 0; i < formattedData.length; i++) {
            let curData = formattedData[i];
            let gr = curData.GR;
            let den = curData.DEN;
            let neut = curData.NEUT;
            let blockID;
            let blockData;
            let value;

            if (view == "GR") {
                value = gr;
            } else if (view == "DEN") {
                value = den;
            } else {
                value = neut;
            }

            for (let j = 0; j < gradient.length; j++) {
                let curBucket = gradient[j];

                if (value >= curBucket.range) {
                    blockID = curBucket.blockID;
                    blockData = curBucket.blockData;
                    break;
                }
            }

            newFormattedData.push({
                biome: { name: "Forest", temp: 0.7, humidity: 0.8 },
                block: { name: "Stone", opaque: true },
                blockId: blockID,
                blockData: blockData,
                index: 0,
                lighting: 0,
                position: curData.position,
                GR: curData.GR,
                NEUT: curData.NEUT,
                DEN: curData.DEN,
            });
        }

        setData(newFormattedData);
    };

    const _onSelect = (selected) => {
        setSelected(selected);
    };

    const selection = {
        blockCount: 15856,
        bounds: {
            maxX: 49,
            maxY: 49,
            maxZ: 49,
            minX: 0,
            minY: 0,
            minZ: 0,
        },
        chunks: [0, 0],
        data: [],
    };

    const bounds = selection.bounds;

    const scale =
        Math.min(window.innerWidth, window.innerHeight) /
        Math.max(
            bounds.maxX - bounds.minX,
            bounds.maxY - bounds.minY,
            bounds.maxZ - bounds.minZ
        );
    const layers = [
        new MinecraftLayer({
            id: "minecraft-layer",
            getTemperature: getBlockTemperature,
            getBlockHumidity: getBlockHumidity,
            getIsBlockOpaque: isBlockOpaque,
            data: data,
            sliceY: Math.floor(
                state.sliceY * selection.bounds.maxY +
                    (1 - state.sliceY) * selection.bounds.minY
            ),
            pickable: true,
            autoHighlight: true,
            highlightColor: [255, 255, 0, 128],
            onHover: _onHoverBlock,
        }),
    ];
    return (
        <div
            onContextMenu={(e) => e.preventDefault()}
            className="w-full h-full"
        >
            <DeckGL
                views={new OrbitView({ orbitAxis: "Y" })}
                viewState={state.viewState}
                controller={true}
                effects={[LIGHTING_EFFECT]}
                onViewStateChange={_onViewStateChange}
                onWebGLInitialized={_onWebGLInitialized}
                layers={layers}
            />

            <p className="depthText">
                {Math.ceil((6500 - state.sliceY * 6500) / 100) * 100} feet
            </p>
            <input
                type="range"
                className="y-slicer"
                min={0.01}
                max={1}
                step={0.01}
                value={state.sliceY}
                onChange={_onSliceY}
            />
            <div className="bg-white w-[500px] h-[125px] m-5 relative">
                <h1>Legend</h1>
                <div className="bg-gradient-to-r from-[#1C0088] to-[#09FF77] h-[50px]" />
            </div>

            <SummaryPanel
                data={selection}
                hoveredBlock={state.hoveredBlock}
                _viewChangeHandler={_viewChangeHandler}
            />
            <MiniMap _onSelect={_onSelect} selected={selected} />
        </div>
    );
}

/* global document */
const root = document.createElement("div");
document.body.appendChild(root);
render(<App />, root);
